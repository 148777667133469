<template>
  <div class="h-screen flex w-full bg-img vx-row no-gutter items-center justify-center" id="page-login">
    <!-- <img src="@/assets/images/pages/login/tiptaplogo_white.png" alt="login" class="mx-auto"> -->
    <!-- sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0  -->
    <div class="vx-col sm:w-1/2 md:w-1/2 lg:w-3/4 xl:w-3/5 sm:m-0 m-4">
      <vx-card>
        <div slot="no-body" class="full-page-bg-color">

          <div class="vx-row no-gutter justify-center items-center">

            <div class="vx-col lg:block lg:w-1/2">
              <img src="@/assets/images/pages/login/tiptaplogo_white.png" alt="login" class="mx-auto">
            </div>

            <!-- lg:w-1/2 sm:w-full md:w-full  -->
            <div class="vx-col lg:w-1/2 sm:w-full md:w-full d-theme-dark-bg">
              <div class="p-8 login-tabs-container">

                <div class="vx-card__title mb-4">
                  <h1 class="mb-4">Tiptap Admin</h1>
                  <p>Bienvenido! Ingrese con su cuenta.</p>
                </div>

                <div>
                  <vs-input
                      type="email"
                      v-validate="'required|email'"
                      name="email"
                      icon-no-border
                      icon="icon icon-user"
                      icon-pack="feather"
                      label-placeholder="Email"
                      v-model="email"
                      class="w-full"/>
                  <span class="text-danger text-sm"  v-show="errors.has('email')">{{ errors.first('email') }}</span>

                  <vs-input
                      type="password"
                      name="password"
                      icon-no-border
                      icon="icon icon-lock"
                      icon-pack="feather"
                      label-placeholder="Contraseña"
                      v-model="password"
                      @keyup.enter="loginJWT"
                      class="w-full mt-6" />
                  <span class="text-danger text-sm"  v-show="errors.has('password')">{{ errors.first('password') }}</span>

                  <div class="flex flex-wrap justify-between my-5">
                      <!-- <vs-checkbox v-model="checkbox_remember_me" class="mb-3">Remember Me</vs-checkbox> -->
                      <router-link to="/password-recovery">Recuperar Contraseña</router-link>
                  </div>
                  <!-- <vs-button type="border">Registrarse</vs-button> -->
                  <vs-button class="float-right mb-6" :disabled="!validateForm" @click="loginJWT">Login</vs-button>
                </div>

              </div>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
  </div>
</template>

<script>
import Fingerprint2 from "fingerprintjs2";

export default {
  data() {
    return {
      email: "",
      password: "",
      checkbox_remember_me: false,
    }
  },
  computed: {
    validateForm() {
      return !this.errors.any() && this.email != '' && this.password != ''
    }
  },
  methods: {
    checkLogin() {
      // If user is already logged in notify
      if (this.$store.state.auth.isUserLoggedIn()) {

        // Close animation if passed as payload
        // this.$vs.loading.close()

        this.$vs.notify({
          time: 8000,
          title: 'Login Attempt',
          text: 'You are already logged in!',
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'warning',
          position: 'top-center',
        })

        return false
      }
      return true
    },
    loginJWT() {
      if (!this.checkLogin()) return

      // Loading
      this.$vs.loading()

      const payload = {
        checkbox_remember_me: this.checkbox_remember_me,
        userDetails: {
          email: this.email,
          password: this.password
        }
      }

      this.$store.dispatch('auth/loginJWT', payload)
        .then(response => {
            this.$vs.loading.close()
            this.$store.dispatch('updateUserRole', {aclChangeRole: this.$acl.change, userRole: response.schools.length > 0 ? 6 : response.profile.profile_type})
            return response
        })
        .then(response => {
          if(response.account.status == 4)
            this.$router.push({name: 'change-password'})
          else
            this.$router.push(response.profile.profile_type == 7 ? {name: 'marketplace-products'} : {name : 'home'})
        })
        .catch(error => {
          this.$vs.loading.close()
          this.$vs.notify({
            time: 8000,
            title: 'Error',
            text: error.message,
            iconPack: 'feather',
            icon: 'icon-alert-circle',
            color: 'danger',
            position: 'top-center',
          })
        })
    },
  },
  mounted() {
    Fingerprint2.getPromise()
      .then(function (components) {
        let values = components.map((component) => component.value);
        return Fingerprint2.x64hash128(values.join(""), 31);
      })
      .then((finger) => {
        this.$store.dispatch("auth/setFingerprint", finger);
      });
  }
}
</script>

<style lang="css" scoped>
  .vx-card {
    background-color: transparent;
  }
  .vx-col > img {
    max-width: 100%;
  }
  .layout--full-page .full-page-bg-color {
    background-color: inherit;
  }
  .vs-con-input-label.is-label-placeholder {
    margin-top: 30px !important;
  }
</style>
